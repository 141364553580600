.expert-card.active:before {
    opacity: 1;
}

.expert-card:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -12px;
    left: 50%;
    height: 26px;
    width: 26px;
    background: #fff;
    border-radius: 5px;
    transform: rotate(45deg);
    opacity: 0;
    transition: 0.4s;
    margin-left: -13px;
}

.slick-arrow {
    display: none !important;
}

/* ( •_•)>⌐■-■.·´¯`(>▂<)´¯`·. ಠ▃ಠ */

/* slick-custom.css */

/* Styles for creative and professional UI dots */
/* slick-custom.css */

/* Styles for creative square UI dots */
.slick-dots {
    bottom: 20px; /* Adjust as needed */
    text-align: center;
}

.slick-dots li {
    margin: 0 5px; /* Adjust spacing between dots */
    display: inline-block;
}

/* Dot container with background */
.slick-dots-container {
    background-color: rgba(
        255,
        255,
        255,
        0.5
    ); /* Adjust background color and opacity */
    padding: 10px; /* Adjust padding */
    border-radius: 20px; /* Adjust border radius */
}

/* Active dot style */
.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #232323 !important;
}

/* Custom square icon */
.slick-dots li button::before {
    margin-top: -4px !important;
    font-size: 25px !important;
    content: "▂" !important; /* Unicode for a solid square */
    color: #232323;
}

::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
